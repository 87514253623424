<template>
  <div id="select-customer"  class="panel-block">
        <label>Bitte wählen Sie einen Kunden aus</label>
        <b-field class="column is-6">
          <b-autocomplete
            :data="customerList"
            placeholder="Kunden auswählen"
            field="title"
            :loading="isFetching"
            @typing="getAsyncData"
            @select="(option) => setCustomer(option)"
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  #{{ props.option.id }} {{ props.option.name }}
                  <br />
                  <small>
                    {{ props.option.zip }} {{ props.option.city }},
                    {{ props.option.country.name_de }}, E-Mail:
                    {{ props.option.email }}
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
</template>

<script>
import debounce from "lodash/debounce"
export default {
  name: "Customerfind",
  data() {
    return {
      customerList: [],
      isFetching: false
    }
  },
  props: {customer:Object},
  methods: {
    getAsyncData: debounce(function(searchphrase) {
      if (searchphrase.length < 3) {
        this.data = []
        return
      }
      var t = this
      this.isFetching = true
      this.$http
        .post("/customers", { search: searchphrase, perpage: 20 })
        .then(function(resp) {
          t.customerList = resp.data.data.data
          t.isFetching = false
        })
        .catch((error) => {
          t.data = []
          throw error
        })
        .finally(() => {
          t.isFetching = false
        })
    }, 200),
    setCustomer: function(customer) {  
      //console.log('setCustomer')    
      this.$emit('customer-selection-changed', customer)
      
    },
    
  }
}
</script>
