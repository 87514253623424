<template>
  <div>
    <div id="customer" class="panel">
      <h2 class="panel-heading is-4">
        <b-icon icon="contact_mail"></b-icon> Kunde
      </h2>
      <customer-field v-if="isReady" :customer="consignment" :changeLink="false" class="panel-block" />
      <div class="columns panel-block" v-if="isReady">
        <div class="column">
          <label>Info</label>
          <div>{{ consignment.info }}</div>
          <div>{{ (consignment.from_private == 1) ? 'von privat = differenzbesteuert' : 'gewerblich mit ausgewiesener MwSt.' }}</div>
        </div>
      </div>
    </div>

    <div id="sequences" class="panel">
      <h2 class="panel-heading is-4">
        <b-icon icon="bottles"></b-icon> Vorgänge
      </h2>

      <b-message type="is-danger" has-icon icon="warning" v-if="consignment.cancelled == 1">
        Der Ankauf wurde am
        {{ $utilities.formatUnixtimeToDmYHi(consignment.cancelled_date) }} Uhr
        durch {{ consignment.cancelled_bouser_name }} abgebrochen.
      </b-message>

      <b-message type="is-success" has-icon icon="check_box" v-if="consignment.instock == 1">
        Der Ankauf wurde abgeschlossen und ins Lager übernommen.
      </b-message>

      <b-collapse class="card" animation="slide" v-for="(s, index) in consignment.sequences" :key="index"
                  :open="index + 1 == consignment.sequences.length ? true : false">
        <div slot="trigger" slot-scope="props" class="card-header" role="button">
          <div class="card-header-title columns">
            <div class="column is-1">
              <b-icon :icon="inOrOut(s.type)"> </b-icon> #{{
                $utilities.padLeftWithNull(s.id)
              }}
            </div>

            <div class="column is-3">
              <b-icon icon="date_range"> </b-icon>
              {{ $utilities.formatUnixtimeToDmYHi(s.created_at) }} Uhr
            </div>
            <div class="column is-2">
              <b-icon icon="person"> </b-icon>
              {{ s.created_bouser_name }}
            </div>
            <div class="column is-5">
              <b-icon icon="check_box" :style="{ color: s.instock == 1 ? '#33cc33' : '#dddddd' }">
              </b-icon>
              <span v-if="s.instock == 1">Eingebucht am
                {{ $utilities.formatUnixtimeToDmYHi(s.instock_date) }}</span>
            </div>
            <div class="column is-1 has-text-right">
              <a class="">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="card-content">
          <sequence-field :s="s" :cancelled="consignment.cancelled" :instock="consignment.instock" :editmode="editmode"
                          :lastSequence="index + 1 == consignment.sequences.length ? true : false
                            " />
        </div>
      </b-collapse>
    </div>
    <div v-if="editmode" id="edit" class="panel">
      <h2 class="panel-heading is-4">
        <b-icon icon="square-edit-outline"></b-icon> Ankauf bearbeiten
      </h2>
      <div id="wines" class="panel">
        <wine-field class="panel-block" :id="'wine' + w.key" v-for="(w, index) in wines" :key="index" :w="w"
                    :wineIndex="index" :inputmode="true" @wine-delete="wineDelete" @wine-copy="wineCopy" />
      </div>
      <div class="panel-block">
        <button @click="addWinefield()" class="button">Wein hinzufügen</button>

        <b-button @click="saveConsignment()" v-if="wines.length >= 1" class="button" type="is-info"
                  icon-left="cloud_upload" native-type="submit">
          Speichern
        </b-button>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
    </div>
    <div class="panel-block">
      <router-link to="/consignments/index#app" class="button" style="margin-right: 10px" custom v-slot="{ navigate }">
        <button @click="navigate" role="link" class="button">
          Zurück zur Übersicht
        </button></router-link>
      <b-button type="is-danger" icon-left="cancel" @click.prevent="consignmentCancel()"
                v-if="parseInt(consignment.cancelled) == 0 && parseInt(consignment.instock) == 0">
        Ankauf abbrechen</b-button>
    </div>
  </div>
</template>

<script>
import CustomerField from "../_helpers/customerfield.vue"
import SequenceField from "../_helpers/sequencefield.vue"
import AddToStock from "./addtostock.vue"

import WineField from "../_helpers/winefield.vue"

export default {
  name: "consignmentView",
  components: {
    CustomerField,
    SequenceField,
    WineField,
    AddToStock
  },

  data () {
    return {
      editmode: false,
      wines: [],
      noOfWineFields: 0,
      isLoading: false
    }
  },
  computed: {
    consignment: function () {
      return this.$parent.consignment
    },
    isReady: function () {
      if (typeof this.consignment == "object") {
        return true
      }

      return false
    }
  },
  created: function () {
    this.$store.commit("setHeadline", {
      subheadline: "Detailansicht"
    })
    //Daten noch nicht geholt
    if (
      typeof this.consignment.id == "undefined" &&
      typeof this.$route.params.id != "undefined"
    ) {
      //console.log('Lade daten nach');
      this.$parent.consignmentView(this.$route.params.id)
    }
    this.$parent.parseImages()
    //console.log(typeof this.consignment)
  },
  methods: {
    inOrOut: function (type) {
      if (type == "in") {
        return "arrow-right"
      } else {
        return "arrow-left"
      }
    },
    consignmentCancel: function () {
      //console.log('Brechhe '+this.consignment.id)
      this.$parent.consignmentCancel(this.consignment.id)
    },
    editSequence: function (id) {
      for (var i = 0; i < this.consignment.sequences.length; i++) {
        if (this.consignment.sequences[i].id == id) {
          for (
            var c = 0;
            c < this.consignment.sequences[i].articles.length;
            c++
          ) {
            this.wines.push(this.consignment.sequences[i].articles[c])
          }

          break
        }
      }
      this.editmode = true
      this.noOfWineFields = this.consignment.sequences[i].articles.length
    },
    addWinefield () {
      this.noOfWineFields++

      this.wines.push({
        index: this.noOfWineFields,
        key: "wine" + this.noOfWineFields,
        bottles: 1,
        price_ek: "0",
        price_exp: "0",
        label: "pf",
        capsula: "pf",
        fill_level: "hf",
        packaging: "no",
        article_id: 0,
        wine_id: 0,
        article: "",
        images: [],
        info_de: "",
        info_en: ""
      })
    },

    async saveConsignment () {
      this.isLoading = true
      var t = this
      t.$http
        .post("/consignments/save", {
          type: "saveNewSequence",
          consignmentId: t.consignment.id,
          wines: t.wines
        })
        .then(function (resp) {
          t.isLoading = false
          //t.$router.push({ path: "/consignments/view/"+resp.data.data });
          //Daten in Eltern setzen
          t.$parent.consignment = resp.data.data
          t.$parent.parseImages()
          t.editmode = false
          t.wines = []
          t.noOfWineFields = 0
          window.scrollTo(0, 0)
          //Auf Ansicht umleiten
          //t.$router.push({ path: "/consignments/view/" + t.consignment.id })
          return true
        })
        .catch(function (error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    },
    wineDelete (index) {
      //console.log("Läsche index " + index);
      this.wines[index].id = "deleted"
    },
    wineCopy (id, no = 1) {
      var t = this

      for (var i = 0; i < no; i++) {
        t.noOfWineFields++
        t.wines.push({
          index: t.noOfWineFields,
          key: "wine" + t.noOfWineFields,
          bottles: 1,
          price_ek: t.wines[id].price_ek,
          price_exp: t.wines[id].price_exp,
          label: t.wines[id].label,
          capsula: t.wines[id].capsula,
          fill_level: t.wines[id].fill_level,
          packaging: t.wines[id].packaging,
          article_id: t.wines[id].article_id,
          wine_id: t.wines[id].wine_id,
          info_de: t.wines[id].info_de,
          info_en: t.wines[id].info_en,
          article: t.wines[id].article,
          images: []
        })
      }
    },
    addToStock (s) {
      var t = this
      t.$buefy.modal.open({
        parent: t,
        component: AddToStock,
        hasModalCard: false,
        width: 960,
        props: { s: s }
      })
    }
  }
}
</script>

<style>
#sequences .card-header-title {
  color: #666666;
}
</style>
