<template>
  <div>
    <div style="width: 100%">
      <wine-field
        :id="'wine' + w.key"
        v-for="(w,index) in s.articles"
        :key="index"
        :w="w"
        :wineIndex="index"
        :inputmode="false"
      />
    </div>
    <div class="buttons">
      <b-button
        type="is-info"
        icon-left="file-pdf"
        @click="downloadOfferPDF(s.id)"
      >PDF Einschätzung</b-button>

     
      <b-button
        v-if="cancelled == 0 && instock == 1 && s.instock==1"
        type="is-info"
        icon-left="file-pdf"
        @click="downloadConfirmationPDF(s.id)"
      >PDF Einlieferungsbestätigung</b-button>

      <b-button
      v-if="cancelled == 0 && instock == 1 && s.instock==1"
        type="is-info"
        icon-left="file-pdf"
        @click="downloadLabelsPDF(s.id)"
      >PDF Label</b-button>

      <b-button
        v-if="cancelled == 0 && instock == 0 && editmode == false  && s.instock==0"
        style="margin-right: 10px;"
        type="is-info"
        icon-left="square-edit-outline"
        @click="editSequence(s.id)"
      >Bearbeiten</b-button>

      <b-button
        v-if="cancelled == 0 && instock == 0 && editmode == false  && s.instock==0 && lastSequence == true"
        type="is-success"
        icon-left="package-variant-closed"
        @click="addToStock(s.id)"
      >Einbuchen</b-button>
    </div>

  </div>
</template>

<script>
import WineField from "../_helpers/winefield.vue";
import LabelsOffset from "../_helpers/labelsoffset.vue";
import AddToStock from "../consignments/addtostock.vue";
export default {
  name: "Sequencefield",
  components: {
    WineField,
    LabelsOffset,
    AddToStock
  },
  props: {
    s: { type: Object, required: true },
    cancelled: { type: [String, Number], required: true },
    instock: { type: [String, Number], required: true },
    editmode:{ type: [Boolean], required: true },
    lastSequence:{ type: [Boolean], required: true },
  },
  
  methods: {
    downloadOfferPDF: function (id) {
      var url =
        this.$store.getters.config.page.apiBaseUrl +
        "/consignments/pdf-sequence/" +
        this.s.id +
        "/" +
        this.s.consignment_id;
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "valuation_" + this.s.consignment_id + "_" + this.s.id + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

     downloadConfirmationPDF: function (id) {
      var url =
        this.$store.getters.config.page.apiBaseUrl +
        "/consignments/pdf-confirmation/" +
        this.s.id +
        "/" +
        this.s.consignment_id;
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "confirmation_" + this.s.consignment_id + "_" + this.s.id + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    editSequence: function(id)
    {
      this.$parent.$parent.editSequence(id);
    },
    addToStock: function(id)
    {      
      this.$parent.$parent.addToStock(this.s);
    },
    downloadLabelsPDF: function(id)
    {    
      var t = this;
      t.$buefy.modal.open({
            parent: t,
            component: LabelsOffset,
            hasModalCard: false,
            width: 500,
      })  
      
    },
    printLabels(offset)
    {
      var url =
        this.$store.getters.config.page.apiBaseUrl +
        "/consignments/pdf-labels/" +
        this.s.id +
        "/" +
        offset;
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "label_" + this.s.consignment_id + "_" + this.s.id + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    }
  },
};
</script>
