<template>
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    :range-before="5"
    :range-after="5"
    paginated
    backend-pagination
    pagination-position="both"
    :total="data.count"
    :per-page="data.perpage"
    @page-change="onPageChange"
    :current-page.sync="data.page"
    aria-next-label="Weiter"
    aria-previous-label="Zurück"
    aria-page-label=" Seite "
    aria-current-label="Aktuelle Seite"
    backend-sorting
    :default-sort="[data.sortField, data.sortOrder]"
    @sort="onSort"
    icon-pack="icm"
    :mobile-cards="false"
    :row-class="(row, index) => row.status"
  >
    
      <b-table-column
        v-slot="props"
        field="Consignment.id"
        label="Id"
        cell-class="status"
        numeric
        sortable
      >
        {{ $utilities.padLeftWithNull(props.row.id) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="Customer.name"
        label="Name"
        sortable
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column v-slot="props" field="info" label="Info" sortable>
        {{ $utilities.shortenString(props.row.info, 40, "..") }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="fcreated_at"
        label="Angelegt"
        sortable
      >
        {{ props.row.fcreated_at }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="fsequence_created_at"
        label="Letzte Aktion"
      >
        {{ props.row.fsequence_created_at }}
        <b-tag
          v-if="
            props.row.sequence_created_at < timestamp &&
            props.row.status == 'grey'
          "
          type="is-danger"
          size="is-small"
          attached
        >
          &gt; 7 Tage
        </b-tag>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Mail"
        class="iconlink"
        field="link0"
        header-class="iconlink"
      >
        <a
          :href="`mailto:${props.row.email}`"
          :title="'E-Mail: ' + props.row.email"
          v-if="props.row.email"
        >
          <b-icon icon="email2"></b-icon>
        </a>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Ans."
        class="iconlink"
        field="link1"
        header-class="iconlink"
      >
        <a
          :href="`/consignments/view/${props.row.id}`"
          title="Ansehen"
          @click.prevent="consignmentView(props.row.id)"
        >
          <b-icon icon="find_in_page"></b-icon>
        </a>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Abbr."
        class="iconlink"
        field="link3"
        header-class="iconlink"
      >
        <a v-if="props.row.status != 'green'"
          @click.prevent="consignmentCancel(props.row.id)"
          title="Ankauf abbrechen"
        >
          <b-icon icon="cancel" class="red"></b-icon>
        </a>
      </b-table-column>
   
  </b-table>
</template>

<script>
export default {
  name: "consignmentList",
  data() {
    return {
      error: "",
      timestamp: 0
    }
  },
  computed: {
    data: function () {
      return this.$parent.data
    },
    isLoading: function () {
      return this.$parent.isLoading
    }
  },
  created: function () {
    this.$store.commit("setHeadline", {
      subheadline: "Übersicht"
    })
    if (this.$route.path != "/consignments/index") {
      this.$router.replace("/consignments/index")
    }
    //7 Tage zurück
    this.timestamp = Math.floor(Date.now() / 1000) - 604800
    this.$parent.consignmentList()
  },

  methods: {
    onPageChange(page) {
      this.$parent.data.page = page
      this.$parent.consignmentList()
    },
    /*
     * Handle sort event
     */
    onSort(field, direction) {
      //console.log(field + ' » ' + direction)
      this.data.sortField = field
      this.data.sortOrder = direction
      this.$parent.data.order = { field, direction }
      this.$parent.consignmentList()
    },
    consignmentDelete: function (id, name) {
      this.$parent.consignmentDelete(id, name)
    },

    consignmentView: function (id) {
      this.$parent.consignmentView(id)
    },
    consignmentEdit: function (id) {
      this.$parent.consignmentEdit(id)
    },
    consignmentCancel: function (id) {
      this.$parent.consignmentCancel(id)
    }
  }
}
</script>
